<template>
    <div class="tfi_web">
        <Frames/>
        <HomeContent/>
    </div>
</template>

<script>
    import Frames from "../../components/frames/Index"
    import HomeContent from "./HomeContent";
    import {mixin1} from "../../tools/mixins";
    import store from "../../store";

    export default {
        name: "Index",
        components: {Frames, HomeContent},
        mixins: [mixin1],
        data() {
            return {
            };
        },
        watch: {
        },
        created() {
            setTimeout(() => {
                this.onAccountsChanged();
            }, 1000)
        },
        mounted() {
        },
        beforeDestroy() {
        },
        methods: {
            onRefresh(accounts) {
                this.$store.commit('setState', {
                    accounts
                });
                localStorage.setItem('accounts', accounts);
                store.dispatch("getHomeData");
            },
            refreshData(val) {
                store.dispatch("getHomeData");
            }
        }
    }
</script>

<style lang="less">
    .tfi_web {
        width: 100%;
        height: 100%;
        min-width: 1080px;
        /*min-height: 730px;*/
        background-color: black;
    }
    @import '../../global.less';
</style>
