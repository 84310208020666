<template>
  <div>
    <div class="slider">
      <div class="slides-wrapper">
        <div class="slides">
          <img
            v-for="imgName in partnerList"
            :src="`${partnerImgPath}/${imgName}`"
            :key="imgName"
            height="70"
            width="170"
            alt=""
          />
        </div>
        <div class="slides clone">
          <img
            v-for="imgName in partnerList"
            :src="`${partnerImgPath}/${imgName}`"
            :key="imgName"
            height="70"
            width="170"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="slider slider-rtl" style="margin-top: 5px">
      <div class="slides-wrapper">
        <div class="slides">
          <img
            v-for="imgName in partnerList"
            :src="`${partnerImgPath}/${imgName}`"
            :key="imgName"
            height="70"
            width="170"
            alt=""
          />
        </div>
        <div class="slides clone">
          <img
            v-for="imgName in partnerList"
            :src="`${partnerImgPath}/${imgName}`"
            :key="imgName"
            height="70"
            width="170"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="slider" style="margin-top: 5px">
      <div class="slides-wrapper">
        <div class="slides">
          <img
            v-for="imgName in partnerList"
            :src="`${partnerImgPath}/${imgName}`"
            :key="imgName"
            height="70"
            width="170"
            alt=""
          />
        </div>
        <div class="slides clone">
          <img
            v-for="imgName in partnerList"
            :src="`${partnerImgPath}/${imgName}`"
            :key="imgName"
            height="70"
            width="170"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RollingPartner",
  data() {
    return {
      partnerImgPath: "/image/partner",
      partnerList: [
        "Au_21capital.png",
        "avalonwealthclub.png",
        "AvStar_Capital.png",
        "Babyswap.png",
        "Bepro.png",
        "bsclaunch.png",
        "BSCStation.png",
        "certik.png",
        "Chain_ridge_capital.png",
        "cheersland.png",
        "chillipadi.png",
        "Coingecko.png",
        "coinmarketcap.png",
        "darkpool_liquidity.png",
        "dutch_crypto_investors.png",
        "fairum_ventures.png",
        "FerrumNetwork.png",
        "gd10ventures.png",
        "Halving_Capital.png",
        "imm_global.png",
        "kangaroocapital.png",
        "koinsaati.png",
        "Math_Wallet.png",
        "Pancakeswap.png",
        "Polkaex.png",
        "Polkaproject.png",
        "Project_Galaxy.png",
        "prostarter.png",
        "Spacecapio.png",
        "Trustpad.png",
        "x21.png",
      ],
    };
  },
};
</script>

<style scoped lang="less">
.slider {
  position: relative;
  display: flex;
  align-items: center;

  width: 100%;
  overflow: hidden;
  max-width: 100%;
}
.slides-wrapper {
  position: relative;
  display: flex;
  animation: scroll 60s linear infinite;
  will-change: transform;
}
.slides {
  display: flex;
}
img {
  margin-right: 15px;
  margin-top: 7px;
}
@keyframes scroll {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-50%, 0);
  }
}

.slider-rtl {
  justify-content: flex-end;
}

.slider-rtl .slides-wrapper {
  animation-name: scrollrev;
}

@keyframes scrollrev {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(50%, 0);
  }
}
</style>
