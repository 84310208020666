<template>
  <div class="home_content">
    <div class="content_top">
      <div class="content_top_text">
        <div class="trusted_text">
          <p>Original pre-IDO platform</p>
          <p>powered by</p>
          <p class="huang">TrustFi BaaS Ecosystem</p>
        </div>
        <div class="trusted_xiang_qing">
          TrustFi Booster is acting as a community-driven incubator and on-chain sponsor to help build and fund the
          crypto startups, as well as community building and market warm-up before their official launch.
        </div>
        <div class="nft_text_d">
          <buy-tfi-btn />
          <div class="d_anniu_b"
               @click="openUrl('https://docs.google.com/forms/d/e/1FAIpQLSca1CqJqUem0Wi_WPMYWaXMWwan8iB7ygp87wOsuoM1DGwtlg/viewform')">
            Apply for Boosting
          </div>
          <div class="d_anniu_c" @click="openUrl('https://solidity.finance/audits/TrustFiBooster/')">Audit Report</div>
        </div>
      </div>

      <div class="content_top_img">
        <img src="../../assets/images/Group.png"/>
      </div>
    </div>

    <div class="central_section">
      <div class="section_yi">
        <div class="yi_text_div">
          <div class="yi_title">
            Why <i class="huang">TrustFi</i> <i class="lui">Booster</i>?
          </div>
          <div class="yi_text">
            TrustFi Booster will list the allocation of the portfolio (Seed/Private Round), allowing community members
            to participate in the donation to the pre IDO stage projects in the way of FCFS. All participants will
            receive Limited NFT Rewards, which will serve as a proof for participating in Booster donations, and it is
            also a reflection of TrustFi loyalty.
          </div>
        </div>
        <div class="yi_img mobHide">
          <img src="../../assets/images/Group.png"/>
        </div>
      </div>

      <div class="section_er">
        <div class="er_img mobHide">
          <img src="../../assets/images/Group.png"/>
        </div>
        <div class="er_text_div">
          <div class="er_title">
            Booster Limited NFT Usage:
          </div>
          <div class="er_text">
            NFT holder will be able to receive/claim the project tokens
          </div>
          <div class="er_text">
            NFTs can be traded and transferred to anyone
          </div>
          <div class="er_text">
            NFTs can be staked to participate in Booster NFT Farm pools
          </div>
          <div class="er_text">
            Other rights and benefits in TrustFi Ecosystem
          </div>
        </div>
      </div>
    </div>

    <div class="content_bottom">
      <rolling-partner />
    </div>

    <footer-v3 />

  </div>
</template>

<script>
  import BuyTfiBtn from "../../components/frames/BuyTfiBtn";
  import FooterV3 from "../../components/frames/FooterV3";
  import RollingPartner from "../../components/frames/RollingPartner";
  export default {
    name: "HomeContent",
    components: {RollingPartner, FooterV3, BuyTfiBtn},
    data() {
      return {
        showSubButtons: false,
      }
    },
    methods: {
      openUrl(url) {
        window.open(url)
      }
    }
  }
</script>

<style lang="less">
  .home_content::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .home_content {
    zoom: 0.9;
    margin: 0 100px;

    position: absolute;
    top: 100px;
    bottom: 0;
    right: 0;
    left: 0;
    min-width: 1080px;
    overflow-y: auto;

    .huang {
      color: #FEE108 !important;
    }

    .lui {
      color: #67F336 !important;
    }

    .content_top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: #FFFFFF;

      .content_top_text {
        margin: 50px;
        width: 60%;
        min-width: 500px;

        .trusted_text {
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 40px;
          line-height: 15px;
        }

        .trusted_xiang_qing {
          font-size: 20px;
        }

        .nft_text_d {
          max-width: 610px;
          color: black;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin-top: 30px;

          .d_anniu_a {
            cursor: pointer;
            width: 190px;
            height: 45px;
            text-align: center;
            line-height: 45px;
            background: #3CD63C;
            border: 1px solid #83DB5C;
            box-sizing: border-box;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
          }

          .d_anniu_b {
            cursor: pointer;
            width: 190px;
            height: 45px;
            text-align: center;
            line-height: 45px;
            background: #FEE108;
            border: 1px solid #FEE108;
            box-sizing: border-box;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
          }

          .d_anniu_c {
            cursor: pointer;
            width: 190px;
            height: 45px;
            text-align: center;
            line-height: 45px;
            background: #40EFE5;
            border: 1px solid #40EFE5;
            box-sizing: border-box;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
          }
        }
      }

      .content_top_img {
        img {
          margin: 50px;
          width: 400px;
          height: 420px;
        }
      }
    }

    .central_section {
      border-top: 5px solid #FEE108;

      .section_yi {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        color: #FFFFFF;

        .yi_text_div {
          margin: 50px;

          .yi_title {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 35px;
            line-height: 15px;
          }

          .yi_text {
            margin-top: 30px;
            font-size: 20px;
          }
        }

        .yi_img {
          img {
            margin: 50px;
            width: 400px;
            height: 420px;
          }
        }
      }

      .section_er {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        color: #FFFFFF;

        .er_img {
          img {
            margin: 50px;
            width: 400px;
            height: 420px;
          }
        }

        .er_text_div {
          margin: 50px;

          .er_title {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 35px;
            line-height: 35px;
          }

          .er_text {
            margin-top: 30px;
            font-size: 20px;
          }
        }
      }
    }

    .content_bottom {
      width: 100vw;
      margin-top: 80px;
      margin-bottom: 200px;

      .ecosystem {
        text-align: center;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 45px;
        line-height: 50px;
        color: #FFFFFF;
      }

      .ecosystem_tb {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: center;

        img {
          width: 140px;
          height: 55px;
          cursor: pointer;
          margin: 30px 0 30px 0;
        }

        .img_1 {
        }

        .img_2 {
        }

        .img_3 {
        }

        .img_4 {
        }

        .img_5 {
        }

        .img_6 {
        }
      }
    }
  }
</style>
